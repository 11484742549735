.loc_button {
    background: var(--main_color);
    height: 40px;
    color: white;
    display: flex;
    width: 230px;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main_color);
    border-radius: 50px;
}
.loc_button img {
    height: 20px;
}

.loc_button_text {
    margin-left: 5px;
}