.driver-image-div {
    /* background-image: url('../../../images/home/home_driver_square.jpg'); */
    /* background-image: url('../../../images/backgrounds/drive_with_us.jpg'); */
    /* background-image: url('../../../images/driver/driver_home.jpg'); */
    background-image: url('../../../images/driver/driver_page_bg.jpg'); 
    background-position: 50% 20%;
    background-size: cover;
}

@media (max-width: 961px){
    .driver-image-div {

        background-image: url('../../../images/driver/driver_page_bg_mobile.jpg'); 
        background-position: 50% 20%;
        background-size: cover;
    }
}