.inner-html h3 {
    font-size: 150%;
}

.inner-html ul {
    list-style-type: disc;
    padding-left: 2rem;
}

.inner-html a {
    color: var(--main_color);
}

