@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}
 
@layer base {
  :root {
    --main_color: "#002fff";
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
 
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
 
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
 
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
 
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
 
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
 
    --ring: 0 0% 63.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
 
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
 
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
 
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
 
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
 
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
 
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
 
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
 
    --ring: 0 0% 14.9%;
  }

}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
body {
  padding-bottom: env(safe-area-inset-bottom);
}

.translate-x-minus-50 {
  transform: translateX(-50%);
}

.height-without-header {
  height: calc(100dvh - 60px);
}

.height-without-header-and-footer {
  height: calc(100dvh - 110px - 1rem);
}

.ion-icon-small-transform {
  transform: translateY(1px);
}
.ion-icon-default-transform {
  transform: translateY(2px);
}
.ion-icon-large-transform {
  transform: translateY(3px);
}

.h-95 {
  height: 95vh;
}

.w-1fourth {
  width: 23%;
}

.transition-fill {
  transition: fill 0.75s ease;
}
.transition-height {
  transition-property: height;
}
.transition-max-height {
  transition-property: max-height;
}

.flip-horizontal {
  transform: scaleX(-1);
}

/* Animations */
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(22px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.bg-main-color {
  background-color: var(--main_color);
}

.bg-gray-150 {
  background-color: rgb(227,229,231);
}

h-2 {
  height: 2px;
}

.ml--2 {
  margin-left: -10px;
}

.ck-powered-by-balloon {
  display: none !important;
}
.h-15 {
  height: 60px;
}
.max-w-1128 {
  max-width: 1128px;
}

.text-header {
  font-size: 0.86rem;
  line-height: 1.2rem;
  font-weight: 500;
}

.right--0\.5 {
  right: -1.4rem;
}

.right--2 {
  right: -1.2rem;
}

.bottom--2 {
  bottom: -1.4rem;
}
.text-editor {
  width: 1086px;
  margin: 0 auto;
}
.ql-editor{
  height: 250px;
  max-height: 350px;
  overflow: auto;
}
@keyframes spin-90 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.spin-90 {
  animation: spin-90 0.5s steps(1) infinite;
}

input[type="text"][disabled] {
  color: black;
}

.text-sm {
  font-size: 15px !important;
}

.text-2xl {
  font-size: 1.65rem !important; /* 24px */

}
