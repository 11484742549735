@font-face {
  font-family: 'BeauSansPro';
  src: url('./fonts/Beau/PFBeauSansPro-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid';
  src: url('./fonts/Euclid/font/Euclid Circular B Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cera';
  src: url('./fonts/CERA/CERA/Cera Pro Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
* {
  font-family: 'Cera', 'BeauSansPro', sans-serif;
}

:root {
  --main_color: #2A69CE;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
