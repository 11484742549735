.question-category-header {

    height: 40px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 7fr 0.7fr 0.6fr;
    align-items: center;
    padding: 0 20px;
    margin: 10px 0;
    border-bottom: 2px solid rgb(243,243,243);
}