.response-header {
    height: 40px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
    align-items: center;
    padding: 0 20px;
    margin: 10px 0;
    border-bottom: 2px solid rgb(243,243,243);
    /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);  */
}