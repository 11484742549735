.qr_wrap_passenger {
    background: url('../../../../images/qr/w_passenger.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.qr_wrap_driver {
    background: url('../../../../images/qr/w_driver.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}