.taxi-cat-item:hover img {
    right: -10px;
}

.taxi-cat-item:hover img.taxi-item-taxi-img {
    right: -26px;
}

.taxi-cat-item:hover img.taxi-item-box {
    right: 0px;
}

@media (max-width: 1900px) {
    .taxi-cat-item:hover img {
        right: 2px;
    }
    .taxi-cat-item:hover img.taxi-item-taxi-img {
        right: -8px;
    }
    .taxi-cat-item:hover img.taxi-item-box {
        right: 20px;
    }
    
}