.contact_header-container {
    height: 95vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.4)), url('../../../images/contact/contact_header.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.underline {
    text-decoration: underline;
}

@media (max-width: 911px){
    .contact_header-container {
        background-position: 52% 50%;
    }
}