.passenger_faq-accordion-wrapper a {
    color: var(--main_color);
}

.passenger-header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.4)), url('../../../images/backgrounds/passengers_couple.jpg');
    background-position: 50% 40%;
    background-size: cover;
}

.main_accordion button {
    font-size: 150%;
}

.secondary_accordion button {
    font-size: 105%;
}
.secondary_accordion div:last-child .py-4 {
    padding-bottom: 0 !important;
}
.secondary_accordion div:last-child {
    border-bottom: none;

}

.passenger_faq_sub_title {
    font-size: 115% !important;
}

.items-center {
    text-align: initial;
}