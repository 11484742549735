.contact_info-wrapper {
    padding: 7vh 10vw;
    
}

.contact_info {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.contact_info-title {
    font-size: 250%;
}

.contact_info-main-mails {
    padding: 30px 0;
}

.contact_info-main-locations-title,
.contact_info-main-mails-title {
    font-size: 150%;
    margin-bottom: 20px;
}

.loc_div {
    margin-bottom: 11px;
}