.admin-sidebar {
    display: flex;
    flex-direction: column;
    padding: 10px 2.5%;
    min-height: 100vh;
    background-color: white;
}
.sidebar-menu {
    height: 100%;
}
.admin-logo img {
    height: 80%;
    padding: 0 2.5%;
}

.link-group-title,
.sidebar-menu-item {
    height: 45px;
    padding: 10px 8px;
    cursor: pointer;
    background-color: white;
    margin: 4px 0;
    border-radius: 8px;
    color: #002fff;

}

.link-group-title.active,
.sidebar-menu-item.active {
    background-color: #002fff;
    color: white;
}

.link-group-title.active .ion-icon-item,
.sidebar-menu-item.active .ion-icon-item {
    color: white;
}

.link-group-title:hover,
.sidebar-menu-item:hover {
    background-color: rgba(0,0,0,0.05);
}

.link-group .sidebar-menu-item:hover {
    background-color: rgb(240 240 240);
}

.link-group .sidebar-menu-item.active,
.link-group-title.active:hover,
.sidebar-menu-item.active:hover {
    background-color: #002fff;
    color: white;
}

.ion-icon-item {
    transform: translateY(3px);
    color: #002fff;
    font-size: 120%;
    margin-right: 2px;
}

.sidebar-menu-item.logout {
    justify-self: flex-end;
    font-size: 105%;
}

.admin-sidebar {
    box-shadow: 5px 0px 3px rgba(0,0,0,0.05);
}
.link-group .link-group-title.sidebar-menu-item {
    font-size: 100%;
}

.link-group .sidebar-menu-item {
    font-size: 95% ;
    height: 38px;
    padding: 6px 8px;
    cursor: pointer;
    background-color: white;
    margin: 4px 0;
    border-radius: 8px;
    color: #002fff;
}

.link-group.active {
    background-color: rgb(173, 198, 237);
    width: calc(100% );
    transform: translateX(- 5px);
    padding: 2px 5px;
    border-radius: 5px;
}