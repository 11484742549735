.header-count {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.header-count.active::after {
    content: "";
    display: block;
    width: 100%;
    height: 5px;
    background-color: #1999e3;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 4px;
    transform: translateX(-50%);
    opacity: 1;
}

.header-count::after {
    content: "";
    display: block;
    width: 10px;
    height: 3px;
    background-color: rgb(120 120 120);
    position: absolute;
    top: 100%; 
    left: 50%;
    width: 100%;
    margin-top: 4px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.header-count:hover::after {
    opacity: 1;
}